import { AnalysisType } from "app/core/klever/feedback/types";

import { Source } from "app/sources/types/models";
import { UserStoryLite } from "app/user-stories/types/responseTypes";

import { FeedbackAnalysisItemLite } from "./KlassyClient";

export enum FeedbackSourceType {
  all = "all",
  appNew = "appNew", // temporary, will become "app" once all customers are migrated.
  csv = "csv",
  dixa = "dixa",
  fireflies = "fireflies",
  freeFormText = "free-form-text",
  freshdesk = "freshdesk",
  front = "front",
  g2 = "g2",
  github = "github",
  gongCalls = "gongCalls",
  hackerNews = "hackerNews",
  hubspot = "hubspot",
  intercom = "intercom",
  jira = "jira",
  kustomer = "kustomer",
  salesforce = "salesforce",
  stackOverflow = "stackOverflow",
  twitter = "twitter",
  zendesk = "zendesk",
  facebook = "facebook",
  instagram = "instagram",
  zapier = "zapier",
  group = "group",
  survey = "survey",
  delighted = "delighted",
  dovetail = "dovetail",
  discord = "discord",
  fathom = "fathom",
  trustpilot = "trustpilot",
  zapier_zendesk = "zapier_zendesk",
  zapier_intercom = "zapier_intercom",
  helpscout = "helpscout",
  slack = "slack",
  zoom = "zoom",
  "googlemeet" = "googlemeet",
  canny = "canny",
  excel = "excel",
  googlesheet = "googlesheet",
  crisp = "crisp",
  notion = "notion",
  clickup = "clickup",
  reddit = "reddit",
  hotjar = "hotjar",
  surveymonkey = "surveymonkey",
  typeform = "typeform",
}

export enum ApifySourceType {
  g2 = "g2",
  trustpilot = "trustpilot",
}

export enum FeedbackSourceStatus {
  creating = "creating",
  initialSync = "initialSync",
  active = "active",
}

export type MergeSyncProblem = {
  id: string;
  description: string;
  status: "ONGOING" | "RESOLVED" | "MERGE_NONSTANDARD_VALUE";
};

export type FeedbackSource<E extends FeedbackSourceExtra = FeedbackSourceExtra> = {
  id: number;
  type: FeedbackSourceType;
  status: FeedbackSourceStatus;
  mergeSyncProblems: MergeSyncProblem[];
  name: string;
  /**
   * An authentication URL used to complete the creation if authentication is required.
   * For example Magic Link for Merge.
   */
  authUrl: string;
  createdAt: string;
  extra: E;
  count?: {
    feedbackAnalysisItems: number;
    feedbackThreads: number;
    feedbackComments: number;
    surveyResponses: number;
  };
} & Partial<Pick<Source, "contentType" | "originService" | "integrationService">>;

export type FeedbackSourceExtra = Record<string, unknown>;

export type FeedbackSourceExtraApp = {
  appId: string;
  country: string;
  iconUrl: string;
  download1YearReviews?: boolean;
};

export type FeedbackSourceExtraZapier = {
  apiToken: string;
  templateUrl?: string;
};

export type FeedbackSourceExtraZapierTranscripts = {
  ingestionApi: boolean;
  ingestionType?: string;
  subtype?: string;
};

export type FeedbackSourceExtraGroup = {
  upstreamSourceIds: number[];
};

export type FeedbackSourceExtraMerge = {
  allowedTags: Array<string>;
  linkToken: string;
};

export type FeedbackSourceExtraFreeFormCSV = {
  analysisType?: AnalysisType;
};

export type FeedbackSourceExtraSurvey = { surveyId: number };

export type ChatMessage = {
  author: "user" | "assistant";
  body: string;
  timestamp: Date;
};

export type ChatBase = {
  id: string;
  messages: Array<ChatMessage>;
  errorCode?: string;
};

/**
 * In an AppChatResponse the sourceId is a klassyAppId
 */
export type AppChatResponse = ChatBase & {
  sourceType: "app";
  sourceId: string;
};

/**
 * In a FeedbackSourceChatResponse the sourceId is a FeedbackSource.id
 */
export type FeedbackSourceChatResponse = ChatBase & {
  sourceType: string;
  sourceId: number;
};

export type Chat = AppChatResponse | FeedbackSourceChatResponse;

export type AnalysisStatus =
  | "analyzing-for-key-points"
  | "analyzing-for-final-results"
  | "analysis-complete";

// Summary sync status types
export type FeedbackSourceAnalysisStatusStage =
  | "initial"
  | "source-created"
  | "auth-completed"
  | "ingestion-started"
  | "ingestion-failed"
  | "ingestion-completed"
  | "analysis-started"
  | "analysis-failed"
  | "analysis-completed"
  | "reverse-analysis-started"
  | "reverse-analysis-failed"
  | "reverse-analysis-completed";

export type FeedbackSourceAnalysisStatusBase = {
  stage: FeedbackSourceAnalysisStatusStage;
};

export type FeedbackSourceAnalysisStatusInitialInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "initial";
};

export type FeedbackSourceAnalysisStatusSourceCreatedInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "source-created";
  context: {
    createdAt: Date;
  };
};

export type FeedbackSourceAnalysisStatusAuthCompletedInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "auth-completed";
  context: {
    createdAt: Date;
  };
};

export type FeedbackSourceAnalysisStatusIngestionStartedInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "ingestion-started";
};

export type FeedbackSourceAnalysisStatusIngestionFaileddInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "ingestion-failed";
  context?: {
    reason: "not-enough-reviews" | "merge-sync-failed";
  };
};

export type FeedbackSourceAnalysisStatusIngestionCompletedInfo =
  FeedbackSourceAnalysisStatusBase & {
    stage: "ingestion-completed";
    context: {
      count: number;
      reason: string;
    };
  };

export type FeedbackSourceAnalysisStatusAnalysisStartedInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "analysis-started";
  context: {
    status: AnalysisStatus;
    count: number;
  };
};

export type FeedbackSourceAnalysisStatusAnalysisFailedInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "analysis-failed";
  context?: {
    reason: "time-limit-reached" | "openai-api";
  };
};

export type FeedbackSourceAnalysisStatusAnalysisCompletedInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "analysis-completed";
  context: {
    count: number;
  };
};

type FeedbackSourceAnalysisStatusReverseAnalysisStartedInfo = FeedbackSourceAnalysisStatusBase & {
  stage: "reverse-analysis-started";
  context: {
    status: AnalysisStatus;
    count: number;
  };
};

export type FeedbackSourceAnalysisStatusReverseAnalysisFailedInfo =
  FeedbackSourceAnalysisStatusBase & {
    stage: "reverse-analysis-failed";
    context?: {
      reason: "time-limit-reached" | "openai-api";
    };
  };

export type FeedbackSourceAnalysisStatusReverseAnalysisCompletedInfo =
  FeedbackSourceAnalysisStatusBase & {
    stage: "reverse-analysis-completed";
    context: {
      count: number;
    };
  };

export type FeedbackSourceAnalysisStatusInfo =
  | FeedbackSourceAnalysisStatusInitialInfo
  | FeedbackSourceAnalysisStatusSourceCreatedInfo
  | FeedbackSourceAnalysisStatusAuthCompletedInfo
  | FeedbackSourceAnalysisStatusIngestionStartedInfo
  | FeedbackSourceAnalysisStatusIngestionFaileddInfo
  | FeedbackSourceAnalysisStatusIngestionCompletedInfo
  | FeedbackSourceAnalysisStatusAnalysisStartedInfo
  | FeedbackSourceAnalysisStatusAnalysisFailedInfo
  | FeedbackSourceAnalysisStatusAnalysisCompletedInfo
  | FeedbackSourceAnalysisStatusReverseAnalysisStartedInfo
  | FeedbackSourceAnalysisStatusReverseAnalysisFailedInfo
  | FeedbackSourceAnalysisStatusReverseAnalysisCompletedInfo;

export type AnalysisItemsData = {
  analysisItems: Array<FeedbackAnalysisItem>;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type AnalysisItemStatus = {
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
  dateStart: Date;
  dateEnd: Date;
};

export type FeedbackAnalysisItem = Omit<FeedbackAnalysisItemLite, "threads"> & {
  dateStart: Date;
  dateEnd: Date;
  threads: Array<{
    extra?: Record<string, any>;
    comments: Array<{
      date: string;
      body: string;
      id: string;
    }>;
  }>;
  userStories: Array<UserStoryLite>;
  feedbackSource?: FeedbackSource;
};

export type FeedbackAnalysisItemNumeric = Omit<FeedbackAnalysisItem, "id"> & { id: number };

export type UpdateMentionResponse = {
  updateType: string;
  sourceAnalysis: number;
  commentUpdated: string;
  targetAnalysis: number;
};

export type DateRangeName =
  | number
  | -1
  | -2
  | -3
  | -4
  | -5
  | -6
  | -7
  | "yesterday"
  | "lastWeek"
  | "lastMonth";

export type CreateBulkSourcesResponse = {
  group: FeedbackSource | null;
  sources: FeedbackSource[];
};
